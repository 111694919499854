import './MiSitio.css'

const Tech = () => {
    return(
        <div className="liveLoveCode">
          <h1>"Live , Love , Code"</h1>
            <br/>
          <h2>Cuánto más amor das, mejor estás...</h2>
        </div>
    );
}

export default Tech;